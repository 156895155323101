import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["prefecture", "code"];
  declare readonly prefectureTarget: HTMLSelectElement;
  declare readonly codeTarget: HTMLSelectElement;
  codeTomSelect!: TomSelect;

  connect() {
    new TomSelect(this.prefectureTarget, {});
    this.codeTomSelect = new TomSelect(this.codeTarget, {});
  }

  update() {
    const prefectureId = this.prefectureTarget.value;
    const url = `/api/locations/${prefectureId}/codes`;

    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": (
          document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement
        ).content,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.codeTomSelect.clearOptions();

        data.forEach((code: { id: string; name: string }) => {
          this.codeTomSelect.addOption({ value: code.id, text: code.name });
        });

        this.codeTomSelect.refreshOptions(false);
      });
  }
}
